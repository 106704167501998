<template>
  <div class="wrapper">
    <a href="/" style="  text-decoration: none;">
      <logo/>
    </a>
    <div class="container">
      <div class="d-flex justify-content-center align-items-center" style="height: 100vh; height: 100vh; position: relative; top: -141px;">
        <div class="error-block">
          <svg style="    position: relative;
    bottom: 14px;" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
            <circle cx="25" cy="25" r="25" fill="#1A78F2"/>
          </svg>
          <svg style="position: absolute;" xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
            <path
                d="M14 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H19C19.5304 1 20.0391 1.21071 20.4142 1.58579C20.7893 1.96086 21 2.46957 21 3V13"
                stroke="white" stroke-width="1.5" stroke-linecap="round"/>
            <path
                d="M1 5.00002H21M4 3.01002L4.01 2.99902M7 3.01002L7.01 2.99902M10 3.01002L10.01 2.99902M17 20.243L19.121 18.121M19.121 18.121L21.243 16M19.121 18.121L17 16M19.121 18.121L21.243 20.243"
                stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

          <h1>{{ this.$route.meta.title }}</h1>

          <h4>{{ this.$route.meta.message }}</h4>

          <hr>
          <p v-if="this.$route.meta.title === '404'">
            При возникновении ошибок в работе программы Вы можете обратиться в службу технической поддержки на почту t3052829@ya.ru или по телефону
            (812) 305-28-29.
          </p>
          <a class="goHome" v-if="this.$route.meta.title === '504'" href="https://llolo.ru/">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M1.37793 11.0011C1.37793 13.5538 2.39199 16.002 4.19703 17.807C6.00206 19.612 8.45022 20.6261 11.0029 20.6261C13.5556 20.6261 16.0038 19.612 17.8088 17.807C19.6139 16.002 20.6279 13.5538 20.6279 11.0011C20.6279 8.44839 19.6139 6.00023 17.8088 4.1952C16.0038 2.39016 13.5556 1.3761 11.0029 1.3761C8.45022 1.3761 6.00206 2.39016 4.19703 4.1952C2.39199 6.00023 1.37793 8.44839 1.37793 11.0011ZM22.0029 11.0011C22.0029 13.9185 20.844 16.7164 18.7811 18.7793C16.7182 20.8422 13.9203 22.0011 11.0029 22.0011C8.08555 22.0011 5.28766 20.8422 3.22476 18.7793C1.16186 16.7164 0.00292969 13.9185 0.00292969 11.0011C0.00292969 8.08372 1.16186 5.28582 3.22476 3.22292C5.28766 1.16002 8.08555 0.00109863 11.0029 0.00109863C13.9203 0.00109863 16.7182 1.16002 18.7811 3.22292C20.844 5.28582 22.0029 8.08372 22.0029 11.0011ZM15.8154 10.3136C15.9978 10.3136 16.1726 10.386 16.3016 10.515C16.4305 10.6439 16.5029 10.8188 16.5029 11.0011C16.5029 11.1834 16.4305 11.3583 16.3016 11.4872C16.1726 11.6162 15.9978 11.6886 15.8154 11.6886H7.85005L10.8022 14.6393C10.8661 14.7033 10.9168 14.7792 10.9514 14.8627C10.986 14.9462 11.0038 15.0357 11.0038 15.1261C11.0038 15.2165 10.986 15.306 10.9514 15.3895C10.9168 15.473 10.8661 15.5489 10.8022 15.6128C10.7383 15.6768 10.6624 15.7275 10.5789 15.7621C10.4953 15.7967 10.4058 15.8145 10.3154 15.8145C10.225 15.8145 10.1355 15.7967 10.052 15.7621C9.96849 15.7275 9.8926 15.6768 9.82868 15.6128L5.70368 11.4878C5.63966 11.424 5.58886 11.3481 5.5542 11.2646C5.51954 11.1811 5.5017 11.0915 5.5017 11.0011C5.5017 10.9107 5.51954 10.8211 5.5542 10.7376C5.58886 10.6541 5.63966 10.5782 5.70368 10.5143L9.82868 6.38935C9.95777 6.26025 10.1329 6.18773 10.3154 6.18773C10.498 6.18773 10.6731 6.26025 10.8022 6.38935C10.9313 6.51844 11.0038 6.69353 11.0038 6.8761C11.0038 7.05867 10.9313 7.23375 10.8022 7.36285L7.85005 10.3136H15.8154Z"
                    fill="#333333"/>
            </svg>
            Вернуться на главную
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Logo from "@/components/Logo";
export default {
  components: {Logo}
}
</script>
