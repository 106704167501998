<template>
  <div class="col-xs-12">
    <div class="logo">
      <div class="dragsSvg">
        <svg xmlns="http://www.w3.org/2000/svg" width="58" height="59" viewBox="0 0 58 59" fill="none">
          <path
              d="M27 23.5C27 22.0555 26.7155 20.6251 26.1627 19.2905C25.6099 17.9559 24.7996 16.7433 23.7782 15.7218C22.7567 14.7004 21.5441 13.8901 20.2095 13.3373C18.8749 12.7845 17.4445 12.5 16 12.5C14.5555 12.5 13.1251 12.7845 11.7905 13.3373C10.4559 13.8901 9.24327 14.7004 8.22183 15.7218C7.20038 16.7433 6.39013 17.9559 5.83733 19.2905C5.28452 20.6251 5 22.0555 5 23.5M27 23.5C27 26.4174 25.8411 29.2153 23.7782 31.2782C21.7153 33.3411 18.9174 34.5 16 34.5C13.0826 34.5 10.2847 33.3411 8.22183 31.2782C6.15893 29.2153 5 26.4174 5 23.5M27 23.5H5"
              stroke="#006FD5" stroke-width="3" stroke-linecap="round"/>
          <path opacity="0.5"
                d="M36.003 17.3686C36.6465 18.0352 37.4162 18.567 38.2674 18.9328C39.1186 19.2987 40.0341 19.4915 40.9605 19.4997C41.8869 19.508 42.8057 19.3317 43.6633 18.981C44.5208 18.6304 45.3 18.1124 45.9553 17.4574C46.6105 16.8024 47.1288 16.0234 47.4798 15.166C47.8308 14.3085 48.0076 13.3897 47.9998 12.4632C47.9919 11.5367 47.7997 10.621 47.4342 9.76964C47.0687 8.91824 46.5374 8.14815 45.8711 7.50431C44.5592 6.21317 42.7903 5.49278 40.9496 5.50005C39.109 5.50733 37.3458 6.24169 36.0442 7.54317C34.7425 8.84464 34.0078 10.6078 34.0001 12.4486C33.9924 14.2893 34.7123 16.0586 36.003 17.3709V17.3686Z"
                stroke="#006FD5" stroke-width="3" stroke-linecap="round"/>
          <path
              d="M50.4681 42.5936C52.1032 40.9215 53.0129 38.672 52.9999 36.3333C52.9868 33.9947 52.052 31.7555 50.3982 30.1018C48.7445 28.448 46.5053 27.5132 44.1667 27.5001C41.828 27.4871 39.5785 28.3968 37.9064 30.0319L29.5319 38.4064C27.8968 40.0785 26.9871 42.328 27.0001 44.6667C27.0132 47.0053 27.948 49.2445 29.6018 50.8982C31.2555 52.552 33.4947 53.4868 35.8333 53.4999C38.172 53.5129 40.4215 52.6032 42.0936 50.9681L50.4681 42.5936Z"
              stroke="#006FD5" stroke-width="3"/>
          <path opacity="0.5" d="M34 34.5C34 34.5 34.6744 38.004 38.5864 41.916C42.4984 45.8232 46 46.5 46 46.5" stroke="#006FD5"
                stroke-width="3"/>
        </svg>
      </div>

      <div class="dragText">
        <span class="rowText"> Льготное </span>
        <span class="rowText"> лекарственное </span>
        <span class="rowText">обеспечение</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Logo"
}
</script>

<style scoped>

</style>
