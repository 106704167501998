<template>
<!--  <div class="container" style="width: 1440px;">-->
  <div class="container">
    <div class="row ">
      <div class=" col-md-12 contentRow">
        <h1>Поддержка пользователей портала</h1>
      </div>
    </div>
    <div class="row ">
      <div class=" col-md-12 contentRow">
        <h5>Добро пожаловать на нашу страницу поддержки пользователей!</h5>
      </div>
    </div>
    <div class="row ">
      <div class=" col-md-12 contentRow">
        <p class="description">
          Здесь Вы можете сообщить о любых проблемах или вопросах, связанных с Вашей работой. Мы предлагаем быстрое и эффективное решение всех Ваших
          вопросов. <br> Благодарим Вас за обращение!
        </p>
      </div>
    </div>
    <div class="row " style="width: 509px;">
      <div class=" col-md-12 contentRow needHelp" style="width: 509px;">
        <!--todo aib515211-->
        <!--<p>-->
        <!--Если возникли вопросы как пользоваться порталом, ответы можете найти <a href="#">здесь</a>-->
        <!--</p>-->
      </div>
    </div>
    <div class="row" style="    margin-top: 19vh;">
      <div class="col-md-12 contacts  mb-0" style="width: 100%">
        <p>
          При возникновении ошибок в работе программы Вы можете <br>
          обратиться в службу технической поддержки <br>
          на почту <span>support@llolo.ru</span> или по телефону <span>8 (812) 305-28-29</span>.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'aboutUs',
  props: {
    msg: String
  }
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
