<template>
  <section class="form" action="#">
    <div class="chat" style="overflow: hidden;">
      <div class="successForm">
        <div class="successMessage" style="margin-top: 87px;">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
            <circle cx="25" cy="25" r="25" fill="#1A78F2"/>
            <path d="M16 26.0442L21.7549 32L34 18" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <h2 style="margin-top: 68px;">Спасибо за обращение!</h2>
          <p style="margin-top: 58px;" @click="copyNumber()">Ваш номер заявки:</p>
          <div class="numberInput">
            <p class="form-control inputNumber">{{ number }} <img :src="$route.path + 'copy.svg'" alt="" @click="copyNumber()"></p>
          </div>
          <div class="text-center textCopied">
            <span v-if="textCopied" style="margin-right: auto; margin-left: auto;">Номер заявки скопирован</span>
          </div>


          <hr style="margin-top: 51px;"/>
          <div class="thanksRequest" style="margin-top: 51px;">Ваша заявка принята, наш менеджер свяжется с Вами в ближайшее время!</div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "successForm",
  props: ['number'],
  components: {},

  data() {
    return {
      currentTime: '',
      textCopied: false,
    };
  },
  mounted() {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    this.currentTime = `${hours}:${minutes}`;
  },
  computed: {
    getLogo() {
      return this.$route.path + 'fillFormAva.png';
    },
  },
  methods: {
    async copyNumber() {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(this.number)
            .then(() => {
              console.log('Текст скопирован в буфер обмена ' + this.number);
            })
            .catch((error) => {
              console.error('Не удалось скопировать текст: ', error);
            });
      } else {
        const textarea = document.createElement('textarea');
        textarea.value = this.number;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        console.log('Текст скопирован в буфер обмена -' + this.number);
      }
      this.textCopied = true;
      setTimeout(() => {
        this.textCopied = false;
      }, 3000);

    },
  }
}
</script>
<style scoped lang="scss">
.form {
  height: 719px !important;
  min-height: 700px;
  overflow: hidden;
  top: 3%;
}

.chat {
  padding-right: 0;
}

.successMessage {
  margin-right: auto;
  margin-left: auto;

  svg {
    position: relative;
    left: 43%;
    width: 50px;
    height: 50px;
    flex-shrink: 0;
  }
}
</style>
