import { createApp } from 'vue'
import App from '@/App.vue'
import { BootstrapVue } from '@vue/compat'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../styles/scss/main.scss'
import router from '@/router'
import axios from "axios";

router.afterEach((to) => {
    if (to.meta.status === 404) {
        router.replace({name: '404', params: {pathMatch: to.path.substring(1).split('/')}})
    }
})

createApp(App).use(
    router,
    BootstrapVue,
    axios,
).mount('#app')
